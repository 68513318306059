import React from "react";
import { useTranslate, usePermissions } from "react-admin";
import CurrentUserController from "../../controllers/CurrentUserController";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import GradeController from "../../controllers/GradeController";
import Divider from "@material-ui/core/Divider";
import Page from "./Page";
import CCTFormLabel from "../components/CCTFormLabel";
import Medaille from "../components/Medaille";
import ResetPasswordModalButton from "../components/ResetPasswordModalButton";

import { TextInput /*required,*/ } from "react-admin";
import { Form } from "react-final-form";
import { reservationFormStyles } from "../components/ChapitreReservationForm";
import MembreController from "../../controllers/MembreController";
import ErrorView from "../components/ErrorView";
import NotificationsController from "../../controllers/NotificationsController";
import NotificationListItem from "../components/NotificationListItem";
import MemberContactEdit from "../components/MemberContactEdit";
import MemberDetailsSharingConsentButton from "../components/MemberDetailsSharingConsentButton";

const maxWidthPage = 1260;

const mobileViewBreakpoint = "sm";
const useStyles = makeStyles((theme) => {
    const reservationStyles = reservationFormStyles(theme);
    return {
        ...reservationStyles,
        root: {},
        article: {
            padding: theme.spacing(1, 8),
            boxSizing: "border-box",

            [theme.breakpoints.down("md")]: {
                padding: theme.spacing(1, 4),
            },
            [theme.breakpoints.down(mobileViewBreakpoint)]: {
                padding: theme.spacing(1, 0),
            },
            margin: "0 auto",
            maxWidth: maxWidthPage,
        },
        form: {
            display: "block",
            height: "auto",

            margin: theme.spacing(4, 0),
            maxWidth: 700,
            [theme.breakpoints.down(mobileViewBreakpoint)]: {
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: 500,
            },
            "& hr": {
                marginBottom: theme.spacing(3),
            },
            "& h2": {
                marginTop: theme.spacing(3),
            },
        },

        link: {
            textDecoration: "none",
        },
        formLine: {
            ...reservationStyles.formLine,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            "& $formLabel": {
                minWidth: 160,
            },
        },
        backLine: {
            display: "block",
            flexBasis: "100%",
        },
        longInput: { flex: 1 },
        edit: {
            marginTop: 0,
        },
    };
});

export default (props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const permissions = usePermissions();
    return (
        <Page>
            <CurrentUserController>
                {(user) => (
                    <>
                        <Medaille codeGrade={user.codeGrade}></Medaille>

                        <article className={classes.article}>
                            <h1>{translate("pages.account.title")}</h1>
                            <Typography variant="h3">
                                {user.displayName}
                            </Typography>
                            <GradeController id={user.codeGrade}>
                                {({ loaded, error, data: grade }) => {
                                    return !loaded ? (
                                        translate("Chargement")
                                    ) : grade ? (
                                        <Typography variant="h6">
                                            {user.dateIntronisation
                                                ? translate(
                                                      "{{grade}} intronisé le {{date}}",
                                                      {
                                                          grade:
                                                              grade.displayName ||
                                                              translate(
                                                                  "Membre"
                                                              ),
                                                          date:
                                                              user.dateIntronisation,
                                                      }
                                                  )
                                                : grade.displayName ||
                                                  translate("Membre")}
                                        </Typography>
                                    ) : null;
                                }}
                            </GradeController>
                            {user.member["Parrain 1"] ||
                            user.member["Parrain 2"] ? (
                                <>
                                    {translate("Parrainé par")}{" "}
                                    {user.member["Parrain 1"] ? (
                                        <MembreController
                                            withLoader={false}
                                            id={user.member["Parrain 1"]}
                                            details="parrain"
                                            renderError={(error) => (
                                                <ErrorView error={error} />
                                            )}
                                        >
                                            {({
                                                loaded,
                                                error,
                                                data: memberDetails,
                                            }) => (
                                                <>
                                                    {loaded
                                                        ? memberDetails.displayName
                                                        : "…"}
                                                    {user.member[
                                                        "Parrain 2"
                                                    ] ? (
                                                        <> {translate("et")} </>
                                                    ) : null}
                                                </>
                                            )}
                                        </MembreController>
                                    ) : null}
                                    {user.member["Parrain 2"] ? (
                                        <MembreController
                                            withLoader={false}
                                            id={user.member["Parrain 2"]}
                                            details="parrain"
                                            renderError={(error) => (
                                                <ErrorView error={error} />
                                            )}
                                        >
                                            {({
                                                loaded,
                                                error,
                                                data: memberDetails,
                                            }) => (
                                                <>
                                                    {loaded
                                                        ? memberDetails.displayName
                                                        : "…"}
                                                </>
                                            )}
                                        </MembreController>
                                    ) : null}
                                </>
                            ) : null}
                            <NotificationsController>
                                {({ loaded, error, data: notifications }) => {
                                    return !loaded ? (
                                        translate("Chargement")
                                    ) : notifications &&
                                      notifications.length ? (
                                        <>
                                            <h2>
                                                {translate(
                                                    "pages.account.notifications"
                                                )}
                                            </h2>
                                            {notifications.map(
                                                (notification, index) => (
                                                    <NotificationListItem
                                                        key={index}
                                                        notification={
                                                            notification
                                                        }
                                                    ></NotificationListItem>
                                                )
                                            )}
                                        </>
                                    ) : null;
                                }}
                            </NotificationsController>
                            <h2>{translate("pages.account.connexion")}</h2>
                            <Divider className={classes.divider} />
                            <Form
                                onSubmit={() => {}}
                                render={({
                                    handleSubmitWithRedirect,
                                    invalid,
                                    form,
                                    ...formProps
                                }) => {
                                    return (
                                        <form className={classes.form}>
                                            <div className={classes.formLine}>
                                                <div
                                                    className={
                                                        classes.formLineInner
                                                    }
                                                >
                                                    <CCTFormLabel
                                                        className={
                                                            classes.formLabel
                                                        }
                                                        component="div"
                                                    >
                                                        {translate(
                                                            "pages.account.email"
                                                        )}
                                                    </CCTFormLabel>

                                                    <Typography
                                                        variant="h3"
                                                        component="span"
                                                        className={
                                                            classes.formLineTextValue
                                                        }
                                                    >
                                                        {user.email}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={classes.formLine}>
                                                <div
                                                    className={
                                                        classes.formLineInner
                                                    }
                                                >
                                                    <CCTFormLabel
                                                        className={
                                                            classes.formLabel
                                                        }
                                                    >
                                                        {translate(
                                                            "pages.account.mdp"
                                                        )}
                                                    </CCTFormLabel>
                                                    <TextInput
                                                        source="fakePassword"
                                                        size="small"
                                                        label={""}
                                                        type="password"
                                                        InputProps={{
                                                            value:
                                                                "thisisnotyourpassword",
                                                        }}
                                                        disabled
                                                        variant="outlined"
                                                        options={{
                                                            label: null,
                                                        }}
                                                    />
                                                    <ResetPasswordModalButton
                                                        className={
                                                            classes.formLineButton
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                            <h2>{translate("pages.account.coordonnees")}</h2>
                            <Divider />
                            {user && permissions?.permissions?.rosterAccess ? (
                                <MemberDetailsSharingConsentButton
                                    variant="text"
                                    memberNumber={user.memberNumber}
                                    codeCommanderie={user.codeCommanderie}
                                >
                                    {translate(
                                        "pages.account.contactDetailsSharingSettings"
                                    )}
                                </MemberDetailsSharingConsentButton>
                            ) : null}
                            <MemberContactEdit
                                classes={{
                                    edit: classes.edit,
                                    form: classes.form,
                                    formLine: classes.formLine,
                                    formLineInner: classes.formLineInner,
                                    formLineFullWidth:
                                        classes.formLineFullWidth,
                                    formLabel: classes.formLabel,
                                    formLineTextValue:
                                        classes.formLineTextValue,
                                    formLineInnerWithBlock:
                                        classes.formLineInnerWithBlock,
                                    formLineBlock: classes.formLineBlock,
                                    formLineFlex: classes.formLineFlex,
                                    bottomSticky: classes.bottomSticky,
                                    responsiveBottomButton:
                                        classes.responsiveBottomButton,
                                    responsiveBottomButtons:
                                        classes.responsiveBottomButtons,
                                    backLine: classes.backLine,
                                }}
                                id={user.memberNumber}
                            />
                        </article>
                    </>
                )}
            </CurrentUserController>
        </Page>
    );
};
