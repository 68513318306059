import React from "react";
import MembreHelper from "../helpers/MembreHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: MembreHelper, loaded: Boolean, error: Error })} props.children
 */
const MembreController = ({ details = false, ...props }) => {
    return (
        <ModelController
            {...props}
            helperClass={MembreHelper}
            resource={
                details === true
                    ? "membre/details"
                    : details
                    ? `membre/${details}`
                    : "membre"
            }
        />
    );
};

export default MembreController;
