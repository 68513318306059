import { fr } from "../../i18n";

export default {
    ...fr,
    Déconnexion: "Déconnexion",
    "Contact information sharing settings":
        "Préférences de partage d'informations",
    "Share my information with members of {{commanderieName}}":
        "Mes coordonnées seront visibles pour tous les membres de {{commanderieName}}",
    "Share my info only with members of my chapter(s)":
        "Mes coordonnées seront visibles pour les membres de ma sous-commanderie",
    "Do not share my info with other members":
        "Mes coordonnées ne seront pas visibles pour les autre membres",
    LIRE: "LIRE",
    menu: {
        home: "Accueil",
        chapitres: "Chapitres",
        chapitresParticipations: "Mes participations",
        chapitresAVenir: "À venir",
        chapitresMesResas: "Mes réservations",
        journal: "Journal",
        privatearea: "Officer Area",
        boutique: "Boutique",
        roster: "Roster",
        admin: "Administration",
    },
    "La page demandée n'existe pas": "La page demandée n'existe pas",
    "Retour à l'accueil": "Retour à l'accueil",
    "Retour au journal": "Retour",
    cookie: {
        settings: "Paramètres cookies",
        accept: "J'accepte",
        decline: "En savoir plus",
        message:
            "Ce site utilise des cookies. En poursuivant la navigation, vous acceptez l'utilisation de cookies.",
    },
    validation: {
        country: {
            max: {
                3: "3 caractères max.",
            },
        },
    },
    pages: {
        home: {
            title: "Accueil",
        },
        chapitres: {
            title: "Chapitres à venir",
        },
        chapitresParticipations: {
            title: "Vous y étiez",
        },
        account: {
            title: "Mon compte",
            connexion: "Connexion",
            coordonnees: "Mes coordonnées",
            adresse1: "Adresse N°1",
            adresse2: "Adresse N°2",
            adresse3: "Adresse N°3",
            adresseSoc: "Adresse Pro.",
            adresseLine1: "Adresse ligne 1",
            adresseLine2: "Adresse ligne 2",
            adresseLine3: "Adresse ligne 3",
            cp: "Code postal",
            ville: "Ville",
            portable1: "Portable 1",
            portable2: "Portable 2",
            telephone: "Téléphone",
            fax: "Fax",
            pays: "Pays",
            region: "Région / état",
            adresseSaisonniere: "Adresse saisonnière",
            addressDates: "Période de résidence",
            email: "Votre e-mail",
            mdp: "Votre mot de passe",
            nom: "Votre nom",
            prenom: "Votre prénom",
            conjoint: "Conjoint",
            spouseTitle: "Titre du conjoint",
            fieldFirstName: "Prénom",
            fieldLastName: "Nom",
            fieldNickname: "Surnom",
            portables: "Téléphones portables",
            notifications: "Notifications",
            business: "Profession",
            profession: "Profession",
            societe: "Société",
            promptUnsavedChanges:
                "Vos modifications n'ont pas été enregistrées. Voulez vous les ignorer et poursuivre la navigation ?",
            contactDetailsSharingSettings: "Mes préférences de partage",
        },
        journal: { title: "Journal" },
        privatearea: { title: "Officer Area" },
        reservations: { title: "Mes réservations" },
        paiementChancellerie: {
            footerTitle: "Payer des droits de chancellerie",
            footerSubTitle:
                "Une fois le dossier de candidature du postulant validé par les membres du Grand Conseil, vous pouvez régler ci-dessous les droits de Chancellerie.",
            restrictedForUsMembers:
                "Les membres de la commanderie d'Amérique doivent continuer à payer les droits de chancellerie comme d'habitude.",
            listCandidats: {
                title: "Candidatures proposées",
                nom: {
                    th: "Candidat",
                },
                createdAt: {
                    th: "Proposé le",
                },
            },
            otherCandidat: { title: "Autre candidat" },
            form: {
                firstName: "Prénom",
                lastName: "Nom",
            },
            footer: {
                title: "Comment devenir membre ?",
                text: "Pour plus d'information et pour proposer un candidat,",
                linkText: "visitez cette page",
            },
            success: "Vous allez être redirigé vers la page de paiement.",
        },
        intronisation: {
            footerTitle: "Proposer une nouvelle intronisation",
            restrictedForUsMembers:
                "Les membres de la commanderie d'Amérique doivent continuer à soumettre leurs demandes comme d'habitude.",
            success:
                "Merci pour votre demande, elle sera traitée dans les meilleurs délais.",
            form: {
                firstName: "Prénom",
                lastName: "Nom",
                dateOfBirth: "Date de naissance",
                email: "E-mail",
                phone: "Téléphone",
                motivations: "Motivations",
                success: "Votre demande a été envoyée",
                nationality: "Nationalité",
                profession: "Profession",
                sponsor1: "Premier parrain",
                sponsor2: "Second parrain",
                sponsor1_placeholder: "Nom et prénom du premier parrain",
                sponsor2_placeholder: "Nom et prénom du second parrain",
            },
        },
        mecenat: {
            footerTitle: "Faire un don",
            success: "Vous allez être redirigé vers la page de paiement.",
            amountLabel: "Montant en euros",
            payButton: "Donner",
            amounts: {
                25: "25€",
                120: "120€",
                150: "150€",
                260: "260€",
                500: "500€",
                1000: "1000€",
                1934: "1934€",
            },
        },
        roster: {
            title: "Roster",
            contactDetailsSharingSettings: "Mes préférences de partage",
        },
    },
    contactDetailsSharingSettingsModal: {
        p1: `A basic foundation of the Confrérie des Chevaliers du Tastevin
        is the friendships we share among one another.`,
        p2: `The Commanderie d’Amerique traditionally has distributed to its
        members a directory with contact information to help promote these
        friendships.`,
        p3: `This is a personal and confidential roster for the convenience
        and use of members only, and it is not to be exploited for commercial
        purposes.`,
        p4: `However, we respect a member’s right to privacy and, therefore,
        provide the option to each member to select with whom their information is shared.`,
        p5: `To that end, you can elect one of the following settings:`,
    },
    Annuler: "Annuler",
    Fermer: "Fermer",
    "Erreur ou annulation, merci de réessayer":
        "Erreur ou annulation, merci de réessayer",
    "Nous vous remercions pour le paiement de votre cotisation":
        "Nous vous remercions pour le paiement de votre cotisation",
    Chapitres: "Chapitres",
    Informations: "Informations",
    "article suivant": "Article suivant",
    "article précédent": "Article précédent",
    "Photos et résumé": "Photos et résumé",
    "Reserver mes places<br/>pour ce chapitre":
        "Réserver mes places<br/>pour ce chapitre",
    Réserver: "Réserver",
    BoutonRéserver: "Réserver",
    "Pour réserver, vous devez payer votre cotisation pour l'année {{year}}":
        "Pour réserver, vous devez payer votre cotisation pour l'année {{year}}",
    "Chapitre suivant": "Chapitre suivant",
    "Vous n'avez fait aucune réservation":
        "Vous n'avez fait aucune réservation",
    "Vous n'avez participé à aucun chapitre":
        "Vous n'avez participé à aucun Chapitre",
    "Aucune réservation": "Aucune réservation",
    "Chapitres à venir": "Chapitres à venir",
    "Tastevin en main": "Tastevin en main",
    Ouvrir_tastevin: "Ouvrir",
    telecharger_roster: "Télécharger le Roster",
    "Retour aux chapitres": "Retour aux chapitres",
    "Vous y étiez": "Vous y étiez",
    "À venir": "À venir",
    Journal: "Journal",
    "Tous les articles": "Tous les articles",
    "Articles de la Confrérie": "Articles de la Confrérie",
    "Tous les chapitres": "Tous les chapitres",
    "Chapitres de la Confrérie": "Chapitres de la Confrérie",
    "Sous-commanderie de {{name}}": "Sous-commanderie de {{name}}",
    Confrérie: "Confrérie",
    "Publié le {{datetime}}": "Publié le {{datetime, LLLL}}",
    "{{dateCourte}}": "{{dateCourte, LL}}",
    " le  ": " le  ",
    "Publié dans ": "Publié dans ",
    "Aucun résultat": "Aucun résultat",
    "Cet élément n'existe pas.": "Cet élément n'existe pas.",
    "Bienvenue {{username}}": "Bienvenue {{username}}",
    "{{grade}} intronisé le {{date}}": "{{grade}} intronisé le {{date, LL}}",
    "Intronisé en {{date, YYYY}}": "Intronisé en {{date, YYYY}}",
    "Mon compte": "Mon compte",
    "Payer ma cotisation": "Payer ma cotisation",
    Payer: "Payer",
    "Payer {{amount}}€": "Payer {{amount}}€",
    Membre: "Membre",
    "Candidat à l'intronisation": "Candidat à l'intronisation",
    Invité: "Invité",
    Prénom: "Prénom",
    Nom: "Nom",
    "Prix unitaire (1 place)": "Prix unitaire (1 place)",
    "Paiement de votre réservation": "Paiement de votre réservation",
    "Paiement de votre cotisation {{year}}":
        "Paiement de votre cotisation {{year}}",
    cotisationModalText:
        "Le paiement de votre cotisation {{year}}  s'élève à  <strong> {{priceTTC}}€</strong>",
    "Au delà de {{count}} place par membre, votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation":
        "Au delà de {{count}} place par membre, votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation",
    "Au delà de {{count}} place par membre, votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation_plural":
        "Au delà de {{count}} places par membre, votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation",
    "Votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation":
        "Votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation",
    "à la sous-commanderie": "à la sous-commanderie",
    "à la confrérie": "à la Confrérie",
    "Invité {{number}}": "Invité {{number}}",
    "Nombre de places": "Nombre de places",
    "Dont places gratuites": "Dont places gratuites",
    "Sous-total HT": "Sous-total HT",
    TVA: "TVA",
    "Total TTC": "Total TTC",
    Payé: "Payé",
    "À payer": "À payer",
    "{{count}} résultats": "{{count}} résultat",
    "{{count}} résultats_plural": "{{count}} résultats",
    "Vous bénéficiez de {{count}} place sur {{nbPlacesDemandees}} demandées":
        "Vous bénéficiez de {{count}} place sur {{nbPlacesDemandees}} demandées.",
    "Vous bénéficiez de {{count}} place sur {{nbPlacesDemandees}} demandées_plural":
        "Vous bénéficiez de {{count}} places sur {{nbPlacesDemandees}} demandées.",
    "{{count}} place sur liste d'attente":
        "{{count}} place a été mise sur liste d'attente.",
    "{{count}} place sur liste d'attente_plural":
        "{{count}} places ont été mises sur liste d'attente.",
    "Veuillez nous contacter pour confirmer la liste de vos invités avant l'événement":
        "Veuillez nous contacter pour confirmer la liste de vos invités avant l'événement.",
    "Toutes les sous-commanderies": "Toutes les sous-commanderies",
    Réservataire: "Réservataire",
    Commentaire: "Commentaire",
    "Votre réservation": "Votre réservation",
    numerus_clausus: {
        part1: "Nous vous informons que ",
        part2:
            "nous ne pouvons plus accepter de nouvelles demandes d'intronisation",
        part3:
            " sur ce Chapitre. Le numerus clausus est atteint. Merci de votre compréhension.",
    },
    "Votre réservation du": "Votre réservation du",
    "Si vous avez besoin de places supplémentaires, merci de contacter la Confrérie":
        "Si vous avez besoin de places supplémentaires, merci de contacter la Confrérie",
    "Nous contacter": "Nous contacter",
    "Ma réservation": "Ma réservation",
    "Voir ma<br/>réservation": "Voir ma<br/>réservation",
    reservation: {
        status: {
            accepted: {
                tiny: "acceptée",
                short: "Votre réservation a été prise en compte",
                long: "Votre réservation a été prise en compte",
            },
            canbepaid: {
                tiny: "acceptée",
                short:
                    "Votre réservation a été prise en compte. Merci de procéder au paiement enfin de la valider définitivement.",
                long:
                    "Votre réservation a été prise en compte. Merci de procéder au paiement enfin de la valider définitivement.",
            },
            already_paid: {
                tiny: "acceptée et payée",
                short:
                    "Merci pour votre paiement, votre réservation est validée.",
                long:
                    "Merci pour votre paiement, votre réservation est validée.",
            },
            refused: {
                tiny: "refusée",
                short:
                    "Nous ne sommes pas en mesure de confirmer votre réservation pour le moment",
                long:
                    "Nous ne sommes pas en mesure de confirmer votre réservation pour le moment",
            },
            request: {
                tiny: "en attente",
                short: "Votre réservation a été transmise",
                long:
                    "Votre demande de réservation sera bientôt traitée. Vous allez recevoir un e-mail de confirmation.",
            },
            waiting_list: {
                tiny: "liste d'attente",
                short: "Votre réservation est sur liste d'attente",
                long: "Votre réservation est sur liste d'attente.",
            },
            partially_accepted: {
                tiny: "partielle",
                short: "XXX",
                long: "XXX",
            },
        },
    },
    Modifier: "Modifier",
    roster: {
        searchLabel: "Rechercher un membre",
        details: "Informations de contact",
        address1: "Adresse principale",
        address2: "Adresse secondaire",
        address3: "Address N°3",
        address4: "Adresse saisonnière",
        ratelimit:
            "Pour des raisons de sécurité le nombre de demandes par minutes est limité. Merci de réessayer dans quelques secondes.",
        noContactInfo: "Informations de contact manquantes",
        contactInfoNotShared:
            "Le membre ne partage pas ses informations de contact",
    },
    UpdatePassword: {
        popupTitle: "Modification de votre mot de passe",
        motDePasseValidate:
            "Le mot de passe doit faire plus de 8 charactères, contenir majuscule et minuscule.",
        identique: "Doit être identique",
        labelPassword: "Votre mot de passe",
        labelPasswordNew: "Nouveau mot de passe",
        labelPasswordConfirm: "Confirmation",
    },
    reservationweb: {
        comment: {
            placeholder: "Indiquez vos allergies et intolérances alimentaires…",
            helperText:
                "Si vous souhaitez être avec un groupe en particulier, merci de l'indiquer, nous nous efforcerons de répondre à votre demande",
        },
        navette: {
            label: "Je souhaite prendre la navette",
        },
        hotel: {
            label: "Choisissez votre hôtel",
            nohotel: "Pas d'hôtel",
        },
    },
    "Vous êtes à jour de votre cotisation":
        "Vous êtes à jour de votre cotisation.",
    billing: {
        "billing address": "Adresse de facturation",
        firstName: "Prénom",
        lastName: "Nom",
        postalCode: "Code postal",
        addressLine1: "Adresse",
        city: "Ville",
        stateOrProvince: "Etat ou région",
        country: "Pays",
    },
};
