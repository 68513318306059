import React, { useState } from "react";
import { useMutation, useNotify, useTranslate } from "react-admin";
import ChapitreReservationPriceTable from "./ChapitreReservationPriceTable";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { Routes } from "../routes";
import Typography from "@material-ui/core/Typography";
import ReservationWebController from "../../controllers/ReservationWebController";
import moment from "moment";
import ChapitreReservationPayButton from "./ChapitreReservationPayButton";
import Tooltip from "@material-ui/core/Tooltip";
import ChapitreReservationStatus, {
    ChapitreReservationStatusBadge,
} from "./ChapitreReservationStatus";
import Alert from "../../components/Alert";
import AlertTitle from "../../components/AlertTitle";
import { AddressForm } from "../../admin/components/AddressForm";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Form } from "react-final-form";
import { CUSTOM_GET } from "../../restProvider";
import SimpleSaveButton from "./SimpleSaveButton";

const getParticipantDisplayName = (participant) => {
    return [participant.firstName, participant.lastName]
        .filter((val) => val)
        .join(" ");
};

function isFormEmpty(form) {
    for (var key in form) {
        if (form[key] === "" || form[key] === null) {
            return true;
        }
    }
    return false;
}

const ChapitreReservationShow = ({ id, chapitre, classes, ...props }) => {
    const translate = useTranslate();
    const [addressInfo, setAddressInfo] = useState(null);
    const [payDisabled, setPayDisabled] = useState(true);
    const [open, setOpen] = useState(false);

    const [paymentPayload, setPaymentPayload] = useState({});
    const [
        pay,
        { loading: payFormLoading, data: payForm, error, total },
    ] = useMutation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [submitted, setSubmitted] = useState(false);

    const notify = useNotify();
    const submit = async (values, complete) => {
        try {
            const [{ data: form }, ...res] = await new Promise(
                (resolve, reject) => {
                    console.log(values);
                    pay(
                        {
                            type: CUSTOM_GET,
                            resource: `reservationweb/${id}/payment?address=${encodeURIComponent(
                                JSON.stringify(values)
                            )}`,
                            payload: paymentPayload,
                        },
                        {
                            onSuccess: (...args) => {
                                resolve(args);
                                try {
                                    const elem = document.createElement("div");
                                    elem.innerHTML = args[0].data;
                                    const htmlForm = elem.firstChild;
                                    //  htmlForm.setAttribute("target", "_blank");
                                    document.body.appendChild(htmlForm);
                                    htmlForm.submit();
                                    setSubmitted(true);
                                } catch (e) {
                                    console.error(e);
                                    setSubmitted(false);
                                }
                            },
                            onFailure: (error) => {
                                reject(error);
                            },
                        }
                    );
                }
            );
        } catch (error) {
            // const returnValue = error && error.errorsByField ? error.errorsByField : null;
            notify(error.message, "error");
            // défini les submitErrors du formulaire
            // On le fait pas, ça marche pas à cause des TextInput de react-admin qui supportent pas submitErrors
            // https://github.com/marmelab/react-admin/issues/4351
            // return returnValue;
        }
    };
    return id && chapitre && chapitre.id ? (
        <ReservationWebController id={id}>
            {({ data: reservation, loaded, error }) => {
                // désactiver le bouton si le formulaire est vide

                const tooltipProps = {
                    title: "",
                    "aria-label": "",
                };
                if (loaded && reservation && !reservation.canBePaid) {
                    tooltipProps.title = (
                        <ChapitreReservationStatus reservation={reservation} />
                    );
                } else {
                    tooltipProps.open = false;
                }
                return (
                    <div>
                        <h2>
                            {translate("Votre réservation du")}{" "}
                            {translate("{{dateCourte}}", {
                                dateCourte: moment(
                                    reservation.createdAt
                                ).toDate(),
                            })}
                        </h2>
                        {reservation && reservation.validationStatus ? (
                            <ChapitreReservationStatusBadge
                                textVariant="long"
                                reservation={reservation}
                                useAlert
                            />
                        ) : null}
                        {reservation &&
                        reservation.participants &&
                        reservation.participants.length ? (
                            <>
                                <Typography
                                    variant="h3"
                                    className={classes.participantsTitle}
                                >
                                    {translate("Participants")}
                                </Typography>
                                <ol>
                                    {reservation.participants.map(
                                        (participant, index) => (
                                            <li
                                                key={index}
                                                className={
                                                    index >=
                                                    reservation.nbPlacesConfirmees
                                                        ? classes.participantWaitingList
                                                        : classes.participant
                                                }
                                            >
                                                {getParticipantDisplayName(
                                                    participant
                                                )}
                                                {participant.type ===
                                                "chevalier" ? (
                                                    <small>
                                                        {" "}
                                                        (
                                                        {translate(
                                                            "Membre"
                                                        ).toLowerCase()}
                                                        )
                                                    </small>
                                                ) : null}
                                                {participant.type ===
                                                "intronise" ? (
                                                    <small>
                                                        {" "}
                                                        (
                                                        {translate(
                                                            "Candidat à l'intronisation"
                                                        ).toLowerCase()}
                                                        )
                                                    </small>
                                                ) : null}
                                            </li>
                                        )
                                    )}
                                </ol>
                            </>
                        ) : null}
                        {reservation &&
                        reservation.nbPlacesDemandees &&
                        reservation.nbPlacesConfirmees &&
                        reservation.nbPlacesDemandees !==
                            reservation.nbPlacesConfirmees ? (
                            <Alert severity="info">
                                <AlertTitle>
                                    {translate(
                                        "Vous bénéficiez de {{count}} place sur {{nbPlacesDemandees}} demandées",
                                        {
                                            count:
                                                reservation.nbPlacesConfirmees,
                                            nbPlacesDemandees:
                                                reservation.nbPlacesDemandees,
                                        }
                                    )}
                                </AlertTitle>
                                {reservation.nbPlacesFileAttente > 0 && (
                                    <>
                                        {translate(
                                            "{{count}} place sur liste d'attente_plural",
                                            {
                                                count:
                                                    reservation.nbPlacesFileAttente,
                                            }
                                        )}
                                        <br />
                                    </>
                                )}
                                {translate(
                                    "Veuillez nous contacter pour confirmer la liste de vos invités avant l'événement"
                                )}
                            </Alert>
                        ) : null}
                        <div className={classes.estimate}>
                            <ChapitreReservationPriceTable
                                data={{
                                    prixUnitaire: chapitre.prixUnitaire,
                                    nbPlaces:
                                        reservation &&
                                        reservation.nbPlacesDemandees
                                            ? reservation.nbPlacesDemandees
                                            : 0,
                                    nbPlacesConfirmees:
                                        reservation &&
                                        reservation.nbPlacesConfirmees
                                            ? reservation.nbPlacesConfirmees
                                            : 0,
                                    nbPlacesGratuites:
                                        reservation &&
                                        reservation.nbPlacesGratuites
                                            ? reservation.nbPlacesGratuites
                                            : 0,
                                    nbPlacesFileAttente:
                                        reservation &&
                                        reservation.nbPlacesFileAttente
                                            ? reservation.nbPlacesFileAttente
                                            : 0,
                                    montantTotalHT: reservation.montantTotalHT,
                                    montantTVA: (
                                        reservation.montantTotal -
                                        reservation.montantTotalHT
                                    ).toFixed(2),
                                    montantTotal: reservation.montantTotal,
                                    montantPaye: reservation.montantPaye,
                                    montantRestant: reservation.montantRestant,
                                }}
                            />
                        </div>
                        <div className={classes.actions}>
                            {reservation.alreadyPaid ? (
                                <div></div>
                            ) : (
                                <>
                                    {reservation.canBePaid && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleClickOpen}
                                        >
                                            {translate("Payer")}
                                        </Button>
                                    )}
                                    {reservation.canBeModified && (
                                        <Button
                                            color="secondary"
                                            component={Link}
                                            to={generatePath(
                                                Routes.chapitreReserver,
                                                {
                                                    id: chapitre.id,
                                                    slug: chapitre.displayName,
                                                    idReservation:
                                                        reservation.id,
                                                    action: "edit",
                                                }
                                            )}
                                        >
                                            {translate("Modifier")}
                                        </Button>
                                    )}
                                </>
                            )}
                            <Form
                                onSubmit={submit}
                                render={({
                                    handleSubmit,
                                    invalid,
                                    form,
                                    ...formProps
                                }) => {
                                    return (
                                        <form>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                handleClose={handleClose}
                                            >
                                                <DialogTitle
                                                    id="payment-reservation-title"
                                                    disableTypography={true}
                                                >
                                                    <h2>
                                                        {translate(
                                                            "Paiement de votre réservation"
                                                        )}
                                                    </h2>
                                                </DialogTitle>
                                                <DialogContent id="alert-dialog-description">
                                                    {reservation.canBePaid ? (
                                                        // désactiver le bouton si le formulaire est vide
                                                        <>
                                                            <h4>
                                                                {translate(
                                                                    "billing.billing address"
                                                                )}
                                                            </h4>
                                                            <AddressForm
                                                                classes={
                                                                    classes
                                                                }
                                                            />
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </DialogContent>
                                                <DialogActions
                                                    className={
                                                        classes.dialogActions
                                                    }
                                                >
                                                    <Button
                                                        onClick={handleClose}
                                                        variant="outlined"
                                                        color="secondary"
                                                        autoFocus
                                                    >
                                                        {translate("Fermer")}
                                                    </Button>
                                                    {chapitre.commanderie ==
                                                        null &&
                                                    reservation.canBePaid ? (
                                                        <SimpleSaveButton
                                                            variant="contained"
                                                            color="secondary"
                                                            invalid={invalid}
                                                            disabled={submitted}
                                                            id={reservation.id}
                                                            label={translate(
                                                                "Payer"
                                                            )}
                                                            icon={null}
                                                            handleSubmitWithRedirect={(
                                                                ...args
                                                            ) => {
                                                                return handleSubmit(
                                                                    ...args
                                                                );
                                                            }}
                                                            saving={
                                                                payFormLoading
                                                            }
                                                            addressInfo={
                                                                addressInfo
                                                            }
                                                        ></SimpleSaveButton>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </DialogActions>
                                            </Dialog>
                                        </form>
                                    );
                                }}
                            />
                        </div>

                        <div style={{ textAlign: "right", marginTop: 8 }}>
                            {
                                reservation.validationStatus === "accepted" &&
                                    (!chapitre.commanderie
                                        ? translate(
                                              "Si vous avez besoin de places supplémentaires, merci de contacter la Confrérie"
                                          )
                                        : null) // TODO Affichage des contacts de la commanderie pour les chapitres de commanderie
                            }
                        </div>
                    </div>
                );
            }}
        </ReservationWebController>
    ) : null;
};

export default ChapitreReservationShow;
