import React from "react";

import { SelectInput, useTranslate, ReferenceInput } from "react-admin";
import Typography from "@material-ui/core/Typography";
import {
    upperCaseFirstLetter,
    lowerCaseAllWordsExceptFirstLetters,
} from "../utils/caseUtils";

/**
 * Liste des hôtels
 * @todo use referencefield and use hotels from GMC ?
 * @param {Object} props
 */
const HotelInput = ({ emptyText, ...props }) => {
    const translate = useTranslate();
    return (
        <ReferenceInput
            allowEmpty
            reference="hotel"
            perPage={1000}
            filter={{
                // Seulement les codes commençant par 1 et 2 (demande de Véronique Villemagne)
                "{{Op.or}}": [
                    {
                        "N° Hôtel": {
                            "{{Op.like}}": "1%",
                        },
                    },
                    {
                        "N° Hôtel": {
                            "{{Op.like}}": "2%",
                        },
                    },
                ],
            }}
            sort={{
                field: "Ville",
                order: "ASC",
            }}
            {...props}
        >
            <SelectInput
                optionText={(hotel) =>
                    hotel ? (
                        <div>
                            {hotel["Ville"] ? (
                                <>
                                    <Typography variant="caption">
                                        {upperCaseFirstLetter(
                                            lowerCaseAllWordsExceptFirstLetters(
                                                hotel["Ville"]
                                            )
                                        )}
                                    </Typography>
                                    {" - "}
                                </>
                            ) : null}
                            {upperCaseFirstLetter(
                                lowerCaseAllWordsExceptFirstLetters(
                                    hotel["Adresse 1"]
                                )
                            )}
                        </div>
                    ) : null
                }
                emptyText={translate(emptyText)}
            />
        </ReferenceInput>
    );
};

export default HotelInput;
