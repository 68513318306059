import React, { useEffect } from "react";
import { useQueryWithStore, useLocale } from "react-admin";
import ModelHelper from "../helpers/ModelHelper";
import Loader from "../front/components/Loader";
/**
 * @param {Object} props
 * @param {function({ data: ModelHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @param {Function} props.helperClass La classe à instancier pour chaque entité
 * @param {boolean} props.withLoader Afficher un loader pendant le chargement. Par défaut : true
 * @returns {{data:ModelHelper[], loaded:boolean, error:Error}}
 */
const ModelListController = ({
    children = () => null,
    page = 1,
    perPage = 100, // -1 -> no limit
    filter = {},
    resource,
    sort = {
        field: "createdAt",
        order: "DESC",
    },
    helperClass = ModelHelper,
    withLoader = true,
    onLoaded = () => null,
}) => {
    const locale = useLocale();
    const { loaded, error, data, total } = useQueryWithStore({
        type: "getList",
        resource,
        payload: {
            pagination: {
                page: page,
                perPage: perPage,
            },
            sort: sort,
            filter: filter,
        },
    });
    useEffect(() => {
        let detached = false;
        if (loaded && !error && !detached) {
            onLoaded(
                data ? data.map((item) => new helperClass(item, locale)) : [],
                total
            );
        }
        return () => {
            detached = true;
        };
    }, [loaded, error, data, total, locale]);
    return withLoader && !loaded ? (
        <Loader error={error} />
    ) : (
        children({
            loaded,
            error,
            data: data ? data.map((item) => new helperClass(item, locale)) : [],
            total,
        })
    );
};

export default ModelListController;
