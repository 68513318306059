import englishMessages from "ra-language-english";

export default {
    ...englishMessages,
    "Robot détecté":
        "Google reCAPTCHA could not verify the authenticity of your request.",
    "Voir la page": "View page",
    Anglais: "English",
    Français: "French",
    en: "English",
    fr: "French",
    Chargement: "Loading",
    Unauthorized: "Unauthorized",
    Enregistrer: "Save",
    Prix: "Price",
    Général: "Main",
    "Les réservations sont ouvertes": "Reservations are open",
    "Les réservations sont fermées": "Reservations are closed",
    "Créer une réservation": "Create reservation",
    "Ajouter un participant": "Add participant",
    "Confrérie des Chevaliers du Tastevin":
        "Confrérie des Chevaliers du Tastevin",
    auth: {
        email: "E-mail",
        forgot: "Create / reset my password",
        motDePasseValidate:
            "Le mot de passe doit faire plus de 8 charactères, contenir majuscule et minuscule.",
        forgotButton: "Get it back",
        forgotSuccess: "You will receive an e-mail.",
        forgotFailure: "There was an error",
        username: "Username (e-mail)",
        resetButton: "Save new password",
        resetFailure: "Could not save your new password…",
        resetSuccess: "Your password has been changed.",
        credentialsRequest: "Request credentials",
        lastName: "Last name",
        firstName: "First name",
        sendCredentialRequest: "Send request",
        dateOfBirth: "Date of birth",
        identityVerification: "Identity verification",
        identityVerificationPlaceholder:
            "Date / chapitre of induction, sponsors names, etc...",
    },
    recaptcha: {
        disclaimer: {
            text: "This site is protected by reCAPTCHA and the Google",
            privacy: "Privacy Policy",
            and: "and",
            terms: "Terms of Service",
            apply: "apply",
        },
    },
    "Pas de résultats": "No result",
    "Nous allons traiter votre demande. Vous pourrez ensuite réinitialiser votre mot de passe":
        "We will handle your request. You will then be able to reset your password.",
    "Remplissez ce formulaire si vous pensez que nous n'avons pas votre adresse e-mail à jour":
        "Submit this form if you think we don't have your current e-mail address.",
    "Sinon, cliquez sur": "Else, click",
    "Si vous ne recevez pas d'email, faites une":
        "If you receive no e-mail, please click",
};
