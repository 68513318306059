import React from "react";
import MembreHelper from "../helpers/MembreHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: MembreHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:MembreHelper[], loaded:boolean, error:Error}}
 */
const MembreListController = (props) => {
    return (
        <ModelListController
            {...props}
            resource="membre"
            helperClass={MembreHelper}
        />
    );
};

export default MembreListController;
