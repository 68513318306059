import MemberHasCommanderieHasTitleHelper from "./MemberHasCommanderieHasTitleHelper";
import ModelHelper from "./ModelHelper";

export default class MemberHasCommanderieHelper extends ModelHelper {
    /**
     * @property {string}
     */
    commanderie;
    /**
     * @property {string}
     */
    memberNumber;
    /**
     * @property {Boolean}
     */
    main;
    /**
     * @property {Number}
     */
    index;
    /**
     * @property {Boolean}
     */
    honoraire;
    /**
     * @property {Boolean}
     */
    honorisCausa;

    set titles(val) {
        this._titles = val.map(
            (c) => new MemberHasCommanderieHasTitleHelper(c, this.locale)
        );
    }
    /**
     * @returns {MemberHasCommanderieHasTitleHelper[]}
     */
    get titles() {
        return this._titles;
    }
}
