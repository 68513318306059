import { en } from "../../i18n";

export default {
    ...en,
    Déconnexion: "Logout",
    "Contact information sharing settings":
        "Contact information sharing settings",
    "Share my information with members of {{commanderieName}}":
        "My contact information will be visible to all members of {{commanderieName}}",
    "Share my info only with members of my chapter(s)":
        "My contact information will be visible only to members of my sous-commanderie",
    "Do not share my info with other members":
        "My contact information will not be visible to other members",
    LIRE: "READ",
    menu: {
        home: "Home",
        chapitres: "Chapitres",
        chapitresParticipations: "My participations",
        chapitresAVenir: "Upcoming",
        chapitresMesResas: "My reservations",
        journal: "Journal",
        boutique: "Shop",
        roster: "Roster",
        admin: "Administration",
    },
    "La page demandée n'existe pas": "The requested page does not exist",
    "Retour à l'accueil": "Back to homepage",
    "Retour au journal": "Back",
    cookie: {
        settings: "Cookie settings",
        accept: "Accept",
        decline: "More",
        message:
            "By continuing browsing this website, you accept the use of cookies.",
    },
    validation: {
        country: {
            max: {
                3: "3 characters max.",
            },
        },
    },
    pages: {
        home: {
            title: "Home",
        },
        chapitres: {
            title: "Upcoming Chapitres",
        },
        chapitresParticipations: {
            title: "You were there",
        },
        account: {
            title: "My account",
            connexion: "Credentials",
            coordonnees: "My contact information",
            adresse1: "Address #1",
            adresse2: "Address #2",
            adresse3: "Address #3",
            adresseSoc: "Business Addr.",
            adresseLine1: "Address line 1",
            adresseLine2: "Address line 2",
            adresseLine3: "Address line 3",
            cp: "Zip code",
            ville: "City",
            portable1: "Cell phone #1",
            portable2: "Cell phone #2",
            telephone: "Phone",
            fax: "Fax",
            pays: "Country",
            region: "State / region",
            adresseSaisonniere: "Seasonal address",
            addressDates: "Residence period",
            email: "Your e-mail",
            mdp: "Your password",
            nom: "Your last name",
            prenom: "Your first name",
            conjoint: "Spouse",
            spouseTitle: "Spouse title",
            fieldFirstName: "First name",
            fieldLastName: "Last name",
            fieldNickname: "Nickname",
            portables: "Cell numbers",
            notifications: "Notifications",
            business: "Profession",
            profession: "Profession",
            societe: "Company",
            promptUnsavedChanges:
                "Your changes have not been saved. Do you want to discard them?",
            contactDetailsSharingSettings: "My sharing settings",
        },
        journal: { title: "Journal" },
        privatearea: { title: "Officer Area" },
        reservations: { title: "My reservations" },
        paiementChancellerie: {
            footerTitle: "Pay Chancery Fees",
            footerSubTitle:
                "Once the candidate's application has been validated by the members of the Grand Council, you may pay the chancery fees below.",
            restrictedForUsMembers:
                "US Members should keep paying chancery fees as usual",
            listCandidats: {
                title: "Suggested candidates",
                nom: {
                    th: "Candidate",
                },
                createdAt: {
                    th: "Suggested on",
                },
            },
            otherCandidat: { title: "Other candidate" },
            form: {
                firstName: "First name",
                lastName: "Last name",
            },
            footer: {
                title: "How to become a member ?",
                text: "For more information and to suggest a candidate,",
                linkText: "visit this page",
            },
            success: "You will be redirected to the payment page.",
        },
        intronisation: {
            footerTitle: "Submit an induction request",
            restrictedForUsMembers:
                "US Members should keep submitting their requests as usual",
            success:
                "Thank you for you submission, it will be processed as soon as possible.",
            form: {
                firstName: "First name",
                lastName: "Last name",
                dateOfBirth: "Date of birth",
                email: "E-mail",
                phone: "Phone number",
                motivations: "Motivations",
                success: "Your request has been sent",
                nationality: "Nationality",
                profession: "Profession",
                sponsor1: "First sponsor",
                sponsor2: "Second sponsor",
                sponsor1_placeholder: "Full name of the first sponsor",
                sponsor2_placeholder: "Full name of the second sponsor",
            },
        },
        mecenat: {
            footerTitle: "Make a donation",
            success: "You will be redirected to the payment page.",
            amountLabel: "Amount in euros",
            payButton: "Donate",
            amounts: {
                25: "€25",
                120: "€120",
                150: "€150",
                260: "€260",
                500: "€500",
                1000: "€1000",
                1934: "€1934",
            },
        },
        roster: {
            title: "Roster",
            contactDetailsSharingSettings: "My sharing settings",
        },
    },
    contactDetailsSharingSettingsModal: {
        p1: `A basic foundation of the Confrérie des Chevaliers du Tastevin
        is the friendships we share among one another.`,
        p2: `The Commanderie d’Amerique traditionally has distributed to its
        members a directory with contact information to help promote these
        friendships.`,
        p3: `This is a personal and confidential roster for the convenience
        and use of members only, and it is not to be exploited for commercial
        purposes.`,
        p4: `However, we respect a member’s right to privacy and, therefore,
        provide the option to each member to select with whom their information is shared.`,
        p5: `To that end, you can elect one of the following settings:`,
    },
    Annuler: "Cancel",
    Fermer: "Close",
    "Erreur ou annulation, merci de réessayer":
        "Erreur ou annulation, merci de réessayer",
    "Nous vous remercions pour le paiement de votre cotisation":
        "Nous vous remercions pour le paiement de votre cotisation",
    Chapitres: "Chapitres",
    "Photos et résumé": "Photos et résumé",
    Informations: "Information",
    "article suivant": "Next article",
    "article précédent": "Previous article",
    "Reserver mes places<br/>pour ce chapitre":
        "Book my seats<br/>for this chapitre",
    Réserver: "Reservation",
    BoutonRéserver: "Place reservation",
    "Pour réserver, vous devez payer votre cotisation pour l'année {{year}}":
        "To make a reservation, you have to pay for your {{year}} membership",
    "Chapitre suivant": "Next Chapitre",
    "Vous n'avez fait aucune réservation": "You have made no reservation",
    "Vous n'avez participé à aucun chapitre":
        "You have not participated in any Chapitre",
    "Chapitres à venir": "Upcoming Chapitres",
    "Tastevin en main": "Tastevin en main",
    Ouvrir_tastevin: "Open",
    telecharger_roster: "Download the Roster",
    "Retour aux chapitres": "Back to chapitres",
    "Vous y étiez": "You were there",
    "À venir": "Soon",
    Journal: "Journal",
    "Tous les articles": "All posts",
    "Articles de la Confrérie": "Posts by the Confrérie",
    "Tous les chapitres": "All chapitres",
    "Chapitres de la Confrérie": "Chapitres of the Confrérie",
    "Sous-commanderie de {{name}}": "Commanderie of {{name}}",
    Confrérie: "Confrérie",
    "Publié le {{datetime}}": "Published on {{datetime, LLLL}}",
    "{{dateCourte}}": "{{dateCourte, LL}}",
    " le  ": "  ",
    "Publié dans ": "Published in ",
    "Aucun résultat": "No result",
    "Cet élément n'existe pas.": "This element does not exist.",
    "Bienvenue {{username}}": "Welcome {{username}}",
    "{{grade}} intronisé le {{date}}": "{{grade}} inducted on {{date, LL}}",
    "Intronisé en {{date, YYYY}}": "Inducted in {{date, YYYY}}",
    "Mon compte": "My account",
    "Payer ma cotisation": "Pay membership fees",
    Payer: "Pay",
    "Payer {{amount}}€": "Pay €{{amount}}",
    Membre: "Member",
    "Candidat à l'intronisation": "Candidate for induction",
    Invité: "Guest",
    "Prix unitaire (1 place)": "Unit price (1 seat)",
    Prénom: "First name",
    Nom: "Name",
    "Paiement de votre réservation": "Payment of your reservation",
    "Paiement de votre cotisation {{year}}":
        "Payment of your {{year}} membership fee",
    cotisationModalText:
        "Le paiement de votre cotisation {{year}}  s'élève à  <strong> {{priceTTC}}€</strong>",
    "Au delà de {{count}} place par membre, votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation":
        "For more than {{count}} seat per member, your request will be forwarded {{aLaConfrerieOuALaSousCommanderie}} for validation.",
    "Au delà de {{count}} place par membre, votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation_plural":
        "For more than {{count}} seats per member, your request will be forwarded {{aLaConfrerieOuALaSousCommanderie}} for validation.",
    "Votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation":
        "Your request will be forwarded {{aLaConfrerieOuALaSousCommanderie}} for validation",
    "à la sous-commanderie": "to the sous-commanderie",
    "à la confrérie": "to the Confrérie",
    "Invité {{number}}": "Guest #{{number}}",
    "Nombre de places": "Number of seats",
    "Dont places gratuites": "Including free-of-charge",
    "Sous-total HT": "Subtotal excl. VAT",
    TVA: "VAT",
    "Total TTC": "Total incl. VAT",
    Payé: "Paid",
    "À payer": "To be paid",
    "{{count}} résultats": "{{count}} result",
    "{{count}} résultats_plural": "{{count}} results",
    "Vous bénéficiez de {{count}} place sur {{nbPlacesDemandees}} demandées":
        "You have the benefit of {{count}} seat out of {{nbPlacesDemandees}} requested.",
    "Vous bénéficiez de {{count}} place sur {{nbPlacesDemandees}} demandées_plural":
        "You have the benefit of {{count}} seats out of {{nbPlacesDemandees}} requested.",
    "{{count}} place sur liste d'attente":
        "{{count}} seat was put on waiting list.",
    "{{count}} place sur liste d'attente_plural":
        "{{count}} seats were put on waiting list.",
    "Veuillez nous contacter pour confirmer la liste de vos invités avant l'événement":
        "Please contact us to confirm the list of participants before the event.",
    "Toutes les sous-commanderies": "All sous-commanderies",
    Réservataire: "Registrant",
    Commentaire: "Comment",
    "Votre réservation": "Your reservation",
    "Votre réservation du": "Your reservation placed on",
    "Si vous avez besoin de places supplémentaires, merci de contacter la Confrérie":
        "If you need more seats, please contact the Confrérie",
    "Nous contacter": "Contact us",
    "Ma réservation": "My reservation",
    numerus_clausus: {
        part1: "We inform you that ",
        part2: "we can no longer accept new requests for induction",
        part3:
            " into this Chapitre. The limit has been reached. Thank you for your understanding",
    },
    "Voir ma<br/>réservation": "Check my<br/>reservation",
    reservation: {
        status: {
            accepted: {
                tiny: "accepted",
                short: "Your reservation was taken into account",
                long: "Your reservation was taken into account",
            },
            canbepaid: {
                tiny: "accepted",
                short:
                    "Your reservation has been taken into account. Please proceed with the payment to finally validate it.",
                long:
                    "Your reservation has been taken into account. Please proceed with the payment to finally validate it.",
            },
            already_paid: {
                tiny: "accepted and paid",
                short:
                    "Thank you for your payment, your reservation is validated",
                long:
                    "Thank you for your payment, your reservation is validated",
            },
            refused: {
                tiny: "refused",
                short:
                    "We are not able to confirm your reservation at this time",
                long:
                    "We are not able to confirm your reservation at this time",
            },
            request: {
                tiny: "pending",
                short: "Your reservation was transmitted",
                long:
                    "Your reservation request will be processed soon. You will receive a confirmation e-mail.",
            },
            waiting_list: {
                tiny: "waiting list",
                short: "Your reservation was added to the waiting list",
                long: "Your reservation was added to the waiting list",
            },
            partially_accepted: {
                tiny: "partial",
                short: "XXX",
                long: "XXX",
            },
        },
    },
    Modifier: "Change",
    roster: {
        searchLabel: "Lookup member",
        details: "Contact information",
        address1: "Main address",
        address2: "Secondary address",
        address3: "Third address",
        address4: "Seasonal address",
        ratelimit:
            "For security reasons the number of requests per minute is limited. Please try again in a few seconds.",
        noContactInfo: "Missing contact information",
        contactInfoNotShared: "Member does not share their contact information",
    },
    UpdatePassword: {
        popupTitle: "Password update",
        motDePasseValidate:
            "The password must be 8 character long and contain upper and lower case characters.",
        identique: "Must be the same",
        labelPassword: "Your password",
        labelPasswordNew: "New password",
        labelPasswordConfirm: "New password confirmation",
    },
    reservationweb: {
        comment: {
            placeholder: "Indicate your food allergies and intolerances…",
            helperText:
                "If you wish to be with a particular group, please indicate it, we will endeavor to respond to your request",
        },
        navette: {
            label: "I wish to take the shuttle bus",
        },
        hotel: {
            label: "Choose your hotel",
            nohotel: "No hotel",
        },
    },
    "Vous êtes à jour de votre cotisation": "Your membership is up to date.",
    billing: {
        "billing address": "Billing address",
        firstName: "First name",
        lastName: "Last name",
        postalCode: "Zip code",
        addressLine1: "Address",
        stateOrProvince: "State or province ",
        city: "City",
        country: "Country",
    },
};
